.banner {
  height: 100vh;
  background-color: #fff;
  background-size: cover;
  background-image: url('../../../public/banners/banner-01.jpg');
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    height: 45vh;
    background-size: auto 45vh;
    background-position-x: 100%;
    + div {
      padding-top: 15px;
      background-color: #fff;
    }
  }

  // .custon-nav {
  //   position: absolute;
  //   height: 100vh;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   z-index: 999;
  //   display: flex;
  //   justify-content: space-between;
  //   align-content: center;
  //   align-items: center;
  //   @media (max-width: 767px) {
  //     height: 45vh;
  //   }
  //   > span {
  //     cursor: pointer;
  //     margin: auto 0.5rem;
  //     font-size: 3em;
  //     line-height: 1em;
  //     background-color: rgba(#000, 0.1);
  //     &:hover {
  //       background-color: rgba(#fff, 1);
  //     }
  //     > span {
  //       text-align: center;
  //       display: block;
  //       width: 28px;
  //       height: 56px;
  //       line-height: 44px;
  //     }
  //   }
  // }

  // .owl-item {
  //   height: 100vh;
  //   color: #272727;
  //   @media (max-width: 767px) {
  //     height: 45vh;
  //   }
  //   &.active {
  //     height: 100vh;
  //     @media (max-width: 767px) {
  //       height: 45vh;
  //     }
  //     .item {
  //       height: 100%;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       position: relative;
  //       > img {
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //         right: 0;
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //         z-index: 0;
  //       }
  //       h1,
  //       .titles {
  //         font-weight: bold;
  //         text-transform: uppercase;
  //         font-size: 3.5em;
  //         margin-left: 3em;
  //         animation: 1s 0.5s fadeInDown both;
  //         @media (max-width: 767px) {
  //           font-size: 1.3em;
  //           margin-left: 0;
  //           text-align: center;
  //           width: 100%;
  //         }
  //       }
  //       .descriptions {
  //         margin-left: 7em;
  //         font-size: 1.5em;
  //         animation: 1s 0.6s fadeInUp both;
  //         @media (max-width: 767px) {
  //           margin: 0;
  //           font-size: 0.9em;
  //           text-align: center;
  //           padding: 1vh 5vh;
  //           width: 100%;
  //         }
  //       }
  //       .btns {
  //         animation: 1s 0.9s flipInX both;
  //       }
  //     }
  //   }
  // }
}
