@import '../../global/style/variables';

.info {
  height: 100vh;
  @media (max-width: 767px) {
    height: auto;
  }
  
  h2 {
    text-transform: uppercase;
    margin-bottom: 22px;
    text-align: center;
    font-weight: bold;
  }

  .form-control {
    background-color: white;
  }
  > .row {
    height: 100%;
  }
  
}

.info-quem-somos {
  background-color: #fff;
  div {
    font-size: 22px;
    color: $secondary;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.info-servicos {
  background-color: #fff;
  background-image: url('../../../public/amoravida-doutor.jpg');
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    background-size: auto 100%;
    background-position-x: 100%;
  }
  
  div {
    // font-weight: bold;
    font-size: 22px;
    ul{
      li  {
        padding-left: 6px;
        &::marker {
          content: "\27A4";
          font-size: 14px;
        }
    }}
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.info-unidades {
  color: #fff;
  // background-color: hsla(195, 60%, 62%, 0.568);
  background-image: url('../../../public/amoravida-unidade.jpg');
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  > div {
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      margin: 0;
      z-index: -1;
      background-color: hsla(195, 60%, 62%, 0.76);
      @media (min-width: 767px) {
        width: 50%;
      }
    }
    
    // font-weight: bold;
    font-size: 22px;
    ul{
      li  {
        padding-left: 6px;
        &::marker {
          content: "\27A4";
          font-size: 14px;
        }
    }}
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.info-equipes {
  color: #fff;
  @media (max-width: 767px) {
    color: #373737;
  }
  background-color: #fff;
  background-image: url('../../../public/amoravida-equipe.jpg');
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  > div {
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      margin: 0;
      z-index: -1;
      background-color: #7886a193;
      @media (min-width: 767px) {
        width: 50%;
      }
    }
    font-weight: bold;
    font-size: 22px;
    ul{
      li  {
        padding-left: 6px;
        &::marker {
          content: "\27A4";
          font-size: 14px;
        }
    }}
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.info-contato {
  
}