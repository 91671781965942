@import '../../global/style/variables';

.whatsapp-top {
  position: fixed;
  right: 0;
  bottom: 55px;
  margin: 0.5rem;
  padding: 0.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
  text-decoration: none; 
  z-index: 999; 
  border-radius: 99%;
  background-color: #00a884;
  width: 40px; 
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 767px) {
    width: 56px; 
    height: 56px;
    bottom: 0px;
  }
}

.scrolling-top {
  position: fixed;
  bottom: 55px;
  left: 0;
  margin: 0.5rem;
  background-color: #fff;
  padding: 0.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
  @media (min-width: 767px) {
    bottom: 0px;
  }
}

header {
  background-color: transparent;
  nav {
    background-color: $primary;
    position: relative;
    font-weight: bold;
    height: 60px;

    > span {
      display: block;
      position: relative;
      height: 60px;
      z-index: 1;
      &.active,
      &[data-active="true"],
      &:hover {
        > a {
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          -o-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
      }
      > a {
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
        transform-style: preserve-3d;

        span {
          margin: 0;
          width: 100%;
          height: 100%;
          line-height: 60px;
          text-align: center;
          position: absolute;
          backface-visibility: hidden;

          &:nth-child(1) {
            color: #fff;
            background-color: $primary;
            -webkit-transform: rotateY(0deg) translateZ(30px);
            -moz-transform: rotateY(0deg) translateZ(30px);
            -o-transform: rotateY(0deg) translateZ(30px);
            -ms-transform: rotateY(0deg) translateZ(30px);
            transform: rotateY(0deg) translateZ(30px);
          }

          &:nth-child(2) {
            color: #fff;
            background-color: $secondary;
            -webkit-transform: rotateY(180deg) translateZ(30px);
            -moz-transform: rotateY(180deg) translateZ(30px);
            -o-transform: rotateY(180deg) translateZ(30px);
            -ms-transform: rotateY(180deg) translateZ(30px);
            transform: rotateY(180deg) translateZ(30px);
          }
        }
      }
    }
  }

  &.set-scrol {
    background-color: rgba(111, 178, 255, 0.527);
    nav {
      height: 48px;
      > span {
        height: 48px;
        > a {
          span {
            height: 48px;
            line-height: 48px;
          }
        }
      }
    }
    img {
      width: 75px;
    }
  }
}

.dropdown-menu {
  &.personalize {
    height: calc(100vh - 50px);

    transform: translate3d(0, 50px, 0px) !important;
    width: 100%;
    border: none;
    border-radius: 0;
    .dropdown-item {
      font-weight: bold;
      padding: 0.7rem 1rem;
      &:focus,
      &:hover {
        background-color: #446a49;
        color: #fff;
      }
    }
    > li {
      margin: 0 15px;
      + li {
        border-top: 1px solid #ccc;
      }
    }
  }
}
