@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import '~bootstrap/scss/bootstrap.scss';


.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.img-thumbnail {
  background-color: transparent;
}

#root {
  padding-top: 26px;
  @media (min-width: 767px) {
    padding-top: 0px;
  }
}