@import '../../global/style/variables';

footer {
  background-color: $primary;  
  color: #fff;
  
  a {
    color: #fff;
    &:hover{
      color: #fff;
    }
  }

}