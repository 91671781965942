.quem-somos-image {
  padding-top: 450px;
  background-image: url('../../../public/amoravida-quemsomos.jpg');
  background-position: center;
}

.text-quem-somos {
  font-style: italic;
  font-size: 1.3rem;
  @media (max-width: 600px) {
    font-size: 100%;
  }

  .button {
    padding: 15px;
    color: #446a49;
    border-color: #446a49;
    border-width: 3px;
    border-style: solid;
    max-width: 320px;
    height: auto;
    font-size: 2rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5rem;
    font-style: italic;
    font-weight: 700;
    text-decoration: none;
    @media (max-width: 600px) {
      font-size: 100%;
    }
  }
}
