.map {
  height: 100vh;
}

.map-marker {
  margin-top: -70px;
  background-color: #fff;
  padding: 0.5rem;
}

.address {
  box-sizing: border-box;
  width: 320px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 7px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  position: absolute;
  left: 0;
  margin-left: 0;
  margin-top: 90px;
}

.addressField {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}
