.galeria {
  // h1,
  // h3 {
  //   color: #446a49;
  //   font-weight: bold;
  // }
  small {
    font-size: 11px;
    i {
      color: #446a49;
    }
  }

  .img {
    position: relative;
    overflow: hidden;
    border: solid 15px transparent;
    height: 275px;

    @media (max-width: 767px) {
      height: 375px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
